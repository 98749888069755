function SpecialDate(selector, config) {
    var $element = $(selector);
    var me = this;
    switch ($element.length) {
        case 0:
            return;
        case 1:
            break;
        default:
            $element.each((index, item) => {
                new SpecialDate(item, config);
            });
            return;
    }

    config = config || {};
    $element.hide();
    var calendar;
    if (config.dateType == 'expirydate') {
        calendar = 'gregorian';
    } else {
        if (dataActive.location.query.lang == 'FA') {
            calendar = 'jalali';
        } else {
            calendar = 'gregorian';
        }
    }
    var wrapDate = new WrapDate(calendar);
    if (config.dateType == 'birthdate') {
        this.years = wrapDate.getPreviousYears();
    } else if (config.dateType == 'expirydate') {
        this.years = wrapDate.getNextYears();
    }
    this.months = wrapDate.rangeNameMonths();
    var dayOptions = wrapDate.setDays();
    var $container = $(view('partials/special-date', { months: me.months, years: me.years }));
    $element.after($container).appendTo($container);
    var day = $container.find('.day');
    var year = $container.find('.year');
    var month = $container.find('.month');
    day.append(dayOptions);
    year.change(function (e) {
        wrapDate.changeYear(e.target);
        $element.val(parseInt($(e.target).val()) + '-' + ((parseInt(month.val()) < 10) ? ('0' + parseInt(month.val())) : parseInt(month.val())) + '-' + ((parseInt(day.val()) < 10) ? ('0' + parseInt(day.val())) : parseInt(day.val())));
    });
    month.change(function (e) {
        wrapDate.changeMonth(e.target);
        $element.val(parseInt(year.val()) + '-' + ((parseInt($(e.target).val()) < 10) ? ('0' + parseInt($(e.target).val())) : parseInt($(e.target).val())) + '-' + ((parseInt(day.val()) < 10) ? ('0' + parseInt(day.val())) : parseInt(day.val())));
    });
    day.change(function (e) {
        $element.val(parseInt(year.val()) + '-' + ((parseInt(month.val()) < 10) ? ('0' + parseInt(month.val())) : parseInt(month.val())) + '-' + ((parseInt($(e.target).val()) < 10) ? ('0' + parseInt($(e.target).val())) : parseInt($(e.target).val())));
    })

    if ($element.val() != "") {
        let yearNumber;
        let monthNumber;
        let dayNumber;
        
        if (config.dateType == 'expirydate') {
            [yearNumber, monthNumber, dayNumber] = $element.val().split('-');
        } else {
            [yearNumber, monthNumber, dayNumber] = (DateFormat($element.val(), 'Y-M-D')).split('-');
            if (calendar == 'jalali') {
                yearNumber = convertPersianNumberToEnglish(yearNumber)
                monthNumber = convertPersianNumberToEnglish(monthNumber)
                dayNumber = convertPersianNumberToEnglish(dayNumber)
            }
        }

        day.val(parseInt(dayNumber, 10));
        month.val(parseInt(monthNumber, 10));
        year.val(yearNumber);
    }
}