function MonthPicker(selector, config) {
    var $element = $(selector);
    switch ($element.length) {
        case 0:
            return;
        case 1:
            break;
        default:
            $element.each((index, item) => {
                new MonthPicker(item, config);
            });
            return;
    }

    config = config || {};
    if (MonthPicker.calendar == undefined) {
        throw new Error('Calendar Not config for date format');
    }
    var calendar = MonthPicker.calendar;
    var wrapDate = new WrapDate(calendar);
    var me = this;
    this.months = wrapDate.abbreviationNameOfMonths();
    var monthPickerWrapper = $(`
    <div class="month-picker-wrapper">
    <div class="month-picker-header"><button class="_desk-h close-btn" type="button">&#215;</button><button class="prev month-picker-control _mob-h" type="button"><i class="fa fa-angle-left"></i></button><button class="next month-picker-control _mob-h" type="button"><i class="fa fa-angle-right"></i></button></div>
    <div class="month-picker-body _mr-0 _ml-0"></div>    
    </div>
    `);
    this.yearsBody = '<div class="month-picker-year"><div class="month-picker-body-header text-center">currentyear</div><div class="month-picker-body-content"><div class="row month-picker-year-body _mr-0 _ml-0"></div></div></div>';
    $element.after(monthPickerWrapper);
    var monthPickerContainer = $element.parent();
    var nextBtn = monthPickerWrapper.find('.next');
    var prevBtn = monthPickerWrapper.find('.prev');

    $element.prev().click(function () {
        (config.beforeShow || (() => { }))();
        me.draw();
        monthPickerWrapper.show();
    });

    $element.click(function () {
        (config.beforeShow || (() => { }))();
        me.draw();
        monthPickerWrapper.show();
    });

    $('html').click(function (event) {
        var $element = $(event.target);
        if (monthPickerContainer.has($element).length == 0) {
            monthPickerWrapper.hide();
        };
    });

    this.draw = function () {
        if (monthPickerWrapper.find('.month-picker-body .month-picker-body-content').length > 0) {
            return;
        }
        var years = '';
        for (var i = 0; i < 2; i++) {
            var calcYear = wrapDate.getYear() + i;
            var targetYear = $(me.yearsBody.replace('currentyear', calcYear));
            for (let index = 0; index < 12; index++) {
                years += '<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center"><div class="month-item _p-10" data-value="' + calcYear + '-' + ((index < 9) ? ('0' + (index + 1)) : (index + 1)) + '">' + me.months[index] + '</div></div>';
            }
            targetYear.find('.month-picker-year-body').append(years);


            monthPickerWrapper.find('.month-picker-body').append(targetYear);
            targetYear.data('year', calcYear);
            if (targetYear.data('year') == new Date($element.val()).getFullYear()) {
                targetYear.addClass('active');
            }
            years = '';
        }
        if ($element.val() == '') {
            monthPickerWrapper.find('.month-picker-body>:first-child').addClass('active');
        }
        me.selectMonth();
        me.controllerArrows();
    }

    this.selectMonth = function () {
        var month = wrapDate.getMonth() + 1;
        var currentMonth = wrapDate.getYear() + '-' + ((month < 10 ? ('0' + month) : month));
        var nextyear = (wrapDate.getYear() + 1) + '-' + ((month < 10 ? ('0' + month) : month));
        var months = monthPickerWrapper.find('.month-item');
        months.each((index, item) => {
            var $item = $(item);
            if ($element.val() == '') {
                if ($item.data('value') == currentMonth) {
                    $(item).addClass('selected-month');
                }
                $element.val(currentMonth);
            } else {
                if ($item.data('value') == $element.val()) {
                    $(item).addClass('selected-month');
                }
            }

            if ($item.data('value') < currentMonth) {
                $item.addClass('disabled-month');
            }

            if ($item.data('value') > nextyear) {
                $item.addClass('disabled-month');
            }
        });
        months.click(function () {
            if ($(this).hasClass('disabled-month')) {
                return;
            } else {
                monthPickerWrapper.find('.selected-month').removeClass('selected-month');
                $(this).addClass('selected-month');
                var selectedDate = monthPickerWrapper.find('.selected-month').data('value');
                $element.val(selectedDate);
                $element.next().next().find('.start-date').val(wrapDate.getFirstDay(selectedDate));
                $element.next().next().find('.end-date').val(wrapDate.getLastDay(selectedDate));
                monthPickerWrapper.hide();
            }
        });
    }

    this.goToNextYear = function () {
        if (prevBtn.prop("disabled") == true) {
            prevBtn.prop("disabled", false);
        }
        if (monthPickerWrapper.find('.active').next().length != 0) {
            monthPickerWrapper.find('.active').removeClass('active').next().addClass('active')
        }
        if (monthPickerWrapper.find('.active').next().next().length == 0) {
            nextBtn.prop("disabled", true);
        }
    }

    this.goToPrevYear = function () {
        if (nextBtn.prop("disabled") == true) {
            nextBtn.prop("disabled", false);
        }
        if (monthPickerWrapper.find('.active').prev().length != 0) {
            monthPickerWrapper.find('.active').removeClass('active').prev().addClass('active')
        }
        if (monthPickerWrapper.find('.active').prev().prev().length == 0) {
            prevBtn.prop("disabled", true);
        }
    }

    this.controllerArrows = function () {
        if (monthPickerWrapper.find('.active').next().length == 0) {
            nextBtn.prop("disabled", true);
        }

        if (monthPickerWrapper.find('.active').prev().length == 0) {
            prevBtn.prop("disabled", true);
        }
    }

    nextBtn.click(this.goToNextYear);
    prevBtn.click(this.goToPrevYear);

}